* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;

  a {
    text-decoration: none;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.defaultInput {
  padding: calc(0.3vh + 0.3vw) calc(0.6vh + 0.6vw);
  border-radius: calc(0.5vh + 0.5vw);
  background-color: rgb(255, 188, 1);
  outline-color: white;
  font-size: 0.8rem;
  font-weight: 600;
  color: black;
  border: 2px solid rgb(255, 188, 1);
  cursor: pointer;
  transition: 0.4s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.defaultInput-Black{
  padding: calc(0.3vh + 0.3vw) calc(0.6vh + 0.6vw);
  border-radius: calc(0.5vh + 0.5vw);
  background-color: rgb(0, 0, 0);
  outline-color: white;
  font-size: 0.8rem;
  font-weight: 600;
  color: rgb(255, 188, 1);
  border: 2px solid rgb(0, 0, 0);
  cursor: pointer;
  transition: 0.4s ease;

  &:hover {
    transform: scale(1.05);
  }
}

.limitWidth{
  width: 50%;
}


.defaultBtn {
  padding: calc(0.3vh + 0.3vw) calc(0.6vh + 0.6vw);
  border-radius: calc(0.5vh + 0.5vw);
  margin: calc(0.2vh + 0.2vw);
  color: rgb(255, 188, 1);
  font-size: 0.8rem;
  font-weight: 600;
  background-color: black;
  border: 2px solid rgb(255, 188, 1);
  cursor: pointer;
  transition: 0.4s ease;

  &:hover {
    transform: scale(1.05);
}
}

.disable{
  opacity: .6;
  pointer-events: none;
}

.submitBtn{
  width: 100%;
}

.invalid-input{
  outline-width: 5px;
  outline-color: red;
  background-color: red;
  color: white;
}
