.about {
    height: 100vh;
    width: 100vw;
    background: #141e30; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #243b55, #141e30); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #243b55, #141e30);

    display: flex;
    justify-content: center;
    align-items: flex-start;

    h1 {
        color: white;
    }
}
