.home {
    height: 100vh;
    width: 100vw;

    position: relative;

    background-color: black;

    .launch_btn {
        position: absolute;
        top: 2%;
        right: 2%;

        background-color: rgb(255, 188, 1);
        color: black;
        font-weight: 700;
        padding: calc(0.4vh + 0.4vw) calc(0.8vh + 0.8vw);
        border-radius: calc(0.4vh + 0.4vw);

        transition: 0.4s ease;

        &:hover {
            transform: scale(1.05);
        }
    }
    .home_logo {
        height: 85vh;

        background-image: url("../../assets/home_logo.png");
        background-repeat: no-repeat;
        background-position-x: center;
        background-size: 100% 100%;

        // background-size: calc(70vh + 70vw) calc(30vh + 30vw);
    }

    .home_vol {
        // position: absolute;

        height: 15vh;

        // bottom: 5%;
        // left: 50%;

        margin: 0 auto;

        // transform: translateX(-50%);

        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 80%;

        .vol {
            color: rgb(255, 188, 1);
            h1,
            h2 {
                text-align: center;
                margin: 0;
            }
        }
    }
}
